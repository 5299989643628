const sideMenu4 = [
    { label: 'Dashboard', url: '/dashboard-admin', icon: 'fi fi-rr-dashboard' },
    { label: 'Data Center', url: '/data-center', icon: 'fi fi-rr-download' },
    { label: 'Riwayat Kiriman', url: '/riwayat/admin/all', icon: 'fi fi-rr-time-past' },
    { label: 'Cek Status', url: '/cek', icon: 'fi fi-rr-search-location' },
    { label: 'Payment', url: '/payment-admin/all', icon: 'fi fi-rr-wallet' },
    //   { label: 'Payment', url: '/payment', icon: 'fi fi-rr-receipt' },
    { label: 'Profil', url: '/profile', icon: 'fi fi-rr-user' },
    {
        label: 'Data Rekonsiliasi',
        url: '#',
        icon: 'fi fi-rr-money-bill-wave',
        menus: [
            { label: 'Rekonsiliasi', url: '/rekonsiliasi', icon: 'fi fi-rr-circle' },
            { label: 'Upload <br> Rekonsiliasi Shipper', url: '/rekonsiliasi/shipper/upload', icon: 'fi fi-rr-circle' },
            { label: 'Upload <br> Rekonsiliasi Kurir', url: '/rekonsiliasi/kurir/upload', icon: 'fi fi-rr-circle' },
            { label: 'Riwayat Rekonsiliasi', url: '/rekonsiliasi/riwayat', icon: 'fi fi-rr-circle' },
        ]
    },
    {
        label: 'Data Shipper',
        url: '#',
        icon: 'fi fi-rr-folder',
        menus: [
            { label: 'Shipper', url: '/shipper', icon: 'fi fi-rr-circle' },
            { label: 'Kiriman', url: '/shipper/recap', icon: 'fi fi-rr-circle' },
            { label: 'Top-Up', url: '/saldo/topup/approval', icon: 'fi fi-rr-circle' },
            { label: 'Withdraw', url: '/saldo/withdraw/approval', icon: 'fi fi-rr-circle' },
            { label: 'Rekap Saldo', url: '/saldo/shipper', icon: 'fi fi-rr-circle' },
            { label: 'Financial Report', url: '/finance/shipper', icon: 'fi fi-rr-circle' },
            // { label: 'Pergerakan<br>Saldo Shipper', url: '/saldo/approval', icon: 'fi fi-rr-circle' },
        ]
    },
    {
        label: 'Laporan',
        url: '#',
        icon: 'fi fi-rr-document-signed',
        menus: [
            { label: 'Buku Besar', url: '/report/bukubesar', icon: 'fi fi-rr-circle' },
            { label: 'Buku Besar New', url: '/report/bukubesar_new', icon: 'fi fi-rr-circle' },
            { label: 'Trial Balance', url: '/report/trialbalance', icon: 'fi fi-rr-circle' },
            { label: 'Bospay', url: '/report/bospay', icon: 'fi fi-rr-circle' },
            { label: 'Hutang Usaha (Kurir)', url: '/report/hutang/kurir', icon: 'fi fi-rr-circle' },
            { label: 'Hutang COD (Shipper)', url: '/report/hutang/shipper', icon: 'fi fi-rr-circle' },
            { label: 'Piutang', url: '/report/piutang-new', icon: 'fi fi-rr-circle' },
            { label: 'PPN Masukan', url: '/report/ppn-masukan', icon: 'fi fi-rr-circle' },
            { label: 'PPN Keluaran', url: '/report/ppn-keluaran', icon: 'fi fi-rr-circle' },
            { label: 'Laba Rugi', url: '/report/labarugi', icon: 'fi fi-rr-circle' },
            { label: 'Neraca', url: '/report/neraca', icon: 'fi fi-rr-circle' },
            { label: 'Neraca New', url: '/report/neraca_new', icon: 'fi fi-rr-circle' },
            { label: 'Jurnal Entri', url: '/report/jurnal-entri', icon: 'fi fi-rr-circle' },
            { label: 'Jurnal Umum', url: '/report/jurnal-group', icon: 'fi fi-rr-circle' },
            { label: 'Request Approval', url: '/report/jurnal-request', icon: 'fi fi-rr-circle' },
            { label: 'Kewajaran', url: '/report/kewajaran', icon: 'fi fi-rr-circle' },
        ]
    },
    {
        label: 'Ticket',
        url: '#',
        icon: 'fi fi-rr-ticket',
        menus: [
            { label: 'Claim', url: '/settings/tickets/claim', icon: 'fi fi-rr-circle' },
        ]
    },

    {
        label: 'Arsip',
        url: '#',
        icon: 'fi fi-rr-box',
        menus: [
            { label: 'Arsip Riwayat Kiriman', url: '/arsip/riwayat', icon: 'fi fi-rr-circle' },
            { label: 'Arsip Daftar Ticket', url: '/arsip/ticket', icon: 'fi fi-rr-circle' },
            { label: 'Arsip Daftar Klaim', url: '/arsip/claim', icon: 'fi fi-rr-circle' },
        ]
    },
    {
        label: 'Master',
        url: '#',
        icon: 'fi fi-rr-ballot',
        menus: [
            { label: 'Data Mitra', url: '/settings/mitra', icon: 'fi fi-rr-users-alt' },
        ]
    },

    {
        label: 'Winpay',
        url: '#',
        icon: 'fi fi-rr-money-wings',
        menus: [
            { label: 'Laporan Payment Gateway', url: '/report/winpay', icon: 'fi fi-rr-circle' },
            { label: 'Upload Rekonsiliasi Winpay', url: '/rekonsiliasi/winpay', icon: 'fi fi-rr-circle' }
        ]
    }
];
export default sideMenu4;